import { useTranslation } from 'next-i18next';

import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isSameDay } from 'date-fns';
import { motion } from 'framer-motion';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import WarningIcon from '@assets/icons/WarningIcon';
import Collapse from '@components/elements/Collapse';
import PriceWrapper from '@components/elements/PriceWrapper';
import Tooltip from '@components/elements/Tooltip';
import withMemoBasketMethods from '@components/HOC/withMemoBasketMethods';
import useDateServiceWithLocale from '@hooks/useDateServiceWithLocale';
import useRenderPrice from '@hooks/useRenderPrice';

import DayAdditionalMealTypesList from './DayAdditionalMealTypesList';
import DayAddonsList from './DayAddonsList';
import DayDishesList from './DayDishesList';

import 'tippy.js/themes/light-border.css';

const BasketDay = ({
  day,
  dayPrice = {},
  addons = [],
  dishes = [],
  mealTypes = [],
  delivery = {},
  dietIri,
  isValidDayRequirements = false,
  errorMessageDayRequirements = null,
  isConnectedElement = false,
  connectedToIri = null,
  // context
  collapsed,
  isBasketEditOrder,
  toggleBasketDay,
  isExpeandedByDefault = true,
  currentDiet,
  isShop,
}) => {
  const isDietDay =
    currentDiet?.deliveryDates?.some(dietDay =>
      isSameDay(new Date(dietDay), new Date(day))
    ) ?? true;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { formatWithLocale } = useDateServiceWithLocale();
  const renderPrice = useRenderPrice({ mayNegative: false });
  const formattedDate = formatWithLocale(day);

  const [isExpandedState, setIsExpandedState] = useState(isExpeandedByDefault);
  const isExpanded = isConnectedElement
    ? isExpandedState
    : collapsed?.[dietIri]?.days?.[formattedDate]?.isOpen ?? true;

  const deliveryPriceParts = isDietDay
    ? 0
    : delivery?.price?.priceParts?.DELIVERY || 0;

  const handleToggleBasketDay = useCallback(() => {
    isConnectedElement
      ? setIsExpandedState(!isExpandedState)
      : dispatch(toggleBasketDay({ date: formattedDate, dietIri }));
  }, [isConnectedElement, isExpandedState, formattedDate, dietIri]);

  return (
    <div tw="py-2 overflow-hidden">
      <motion.header initial={false} onClick={handleToggleBasketDay}>
        <div tw="px-2 py-1 text-sm font-semibold bg-gray-0 rounded-md cursor-pointer">
          <div tw="flex justify-between">
            <div tw="capitalize flex items-center">
              <span tw="mr-2">{formatWithLocale(day, 'eeee')}</span>

              {!isBasketEditOrder && !isValidDayRequirements && (
                <Tooltip
                  content={
                    <div tw="text-xs text-red-1">
                      {errorMessageDayRequirements}
                    </div>
                  }
                  maxWidth={300}
                  theme="light-border"
                >
                  <div tw="p-2 -m-2">
                    <WarningIcon tw="text-red-1 w-4" />
                  </div>
                </Tooltip>
              )}
            </div>
            <div>
              <FontAwesomeIcon
                icon={isExpanded ? faChevronUp : faChevronDown}
              />
            </div>
          </div>
          <div tw="flex justify-between">
            <div>{formatWithLocale(day, 'dd.MM.yyyy')}</div>
            <motion.div
              initial="initial"
              animate={isExpanded ? 'initial' : 'animated'}
              variants={{
                initial: { opacity: 0 },
                animated: { opacity: 1 },
              }}
            >
              <PriceWrapper
                beforeDiscount={dayPrice.beforeDiscount}
                afterDiscount={dayPrice.afterDiscount}
              />
            </motion.div>
          </div>
        </div>
      </motion.header>

      <Collapse isExpanded={isExpanded} styles={tw`px-2`}>
        <div tw="flex justify-between border-b border-dashed pt-3 pb-2 mb-3 border-primary font-semibold text-primary text-sm">
          <div>{t('$*components.basketDay.pricePerDay', 'Cena za dzień')}</div>
          <div>
            <PriceWrapper
              beforeDiscount={
                isEmpty(delivery?.price)
                  ? dayPrice.beforeDiscount
                  : dayPrice.beforeDiscount + deliveryPriceParts
              }
              afterDiscount={
                isEmpty(delivery?.price)
                  ? dayPrice.afterDiscount
                  : dayPrice.afterDiscount + deliveryPriceParts
              }
            />
          </div>
        </div>

        <div tw="-my-2">
          <DayDishesList
            dishes={dishes}
            day={formatWithLocale(day)}
            dietIri={dietIri}
            connectedToIri={connectedToIri}
          />

          <DayAdditionalMealTypesList
            mealTypes={mealTypes}
            day={formatWithLocale(day)}
            dietIri={dietIri}
            connectedToIri={connectedToIri}
          />

          <DayAddonsList
            addons={addons}
            day={formatWithLocale(day)}
            dietIri={dietIri}
            connectedToIri={connectedToIri}
          />
        </div>

        {!isEmpty(delivery?.price) &&
          (!isEmpty(currentDiet) ? !isDietDay : isShop) && (
            <p tw="flex justify-between w-full font-bold text-sm">
              <p>{t('$*common.basketNewOrder.delivery', 'Dostawa:')}</p>
              <p>{renderPrice(delivery?.price?.priceParts?.DELIVERY)}</p>
            </p>
          )}
      </Collapse>
    </div>
  );
};

export default withMemoBasketMethods(BasketDay, [
  { as: 'collapsed', path: 'basketStore.collapsed' },
  'isBasketEditOrder',
  'toggleBasketDay',
  'currentDiet',
]);
